import React, { useState, useEffect } from 'react'
import Banner from '../common/banner'
import Autocomplete from '@mui/material/Autocomplete';
import {
    Container,
    Card,
    TextField,
    Button,
    Grid,
    Typography,
    IconButton,
    Box
} from '@mui/material'
import translate from "translate";
import MicRoundedIcon from '@mui/icons-material/MicRounded';
import StopRoundedIcon from '@mui/icons-material/StopRounded';
import ContentCopyRoundedIcon from '@mui/icons-material/ContentCopyRounded';
import toast from 'react-hot-toast'
const langs = [
    { "label": "English", "key": "en" },
    { "label": "Spanish", "key": "es" },
    { "label": "French", "key": "fr" },
    { "label": "German", "key": "de" },
    { "label": "Chinese (Simplified)", "key": "zh-CN" },
    { "label": "Chinese (Traditional)", "key": "zh-TW" },
    { "label": "Japanese", "key": "ja" },
    { "label": "Korean", "key": "ko" },
    { "label": "Arabic", "key": "ar" },
    { "label": "Russian", "key": "ru" },
    { "label": "Portuguese", "key": "pt" },
    { "label": "Italian", "key": "it" },
    { "label": "Dutch", "key": "nl" },
    { "label": "Swedish", "key": "sv" },
    { "label": "Norwegian", "key": "no" },
    { "label": "Finnish", "key": "fi" },
    { "label": "Danish", "key": "da" },
    { "label": "Polish", "key": "pl" },
    { "label": "Greek", "key": "el" },
    { "label": "Turkish", "key": "tr" },
    { "label": "Hindi", "key": "hi" },
    { "label": "Bengali", "key": "bn" },
    { "label": "Urdu", "key": "ur" },
    { "label": "Persian", "key": "fa" },
    { "label": "Hebrew", "key": "he" },
    { "label": "Thai", "key": "th" },
    { "label": "Indonesian", "key": "id" },
    { "label": "Malay", "key": "ms" },
    { "label": "Vietnamese", "key": "vi" },
    { "label": "Tagalog", "key": "tl" },
    { "label": "Swahili", "key": "sw" },
    { "label": "Hausa", "key": "ha" },
    { "label": "Czech", "key": "cs" },
    { "label": "Slovak", "key": "sk" },
    { "label": "Hungarian", "key": "hu" },
    { "label": "Romanian", "key": "ro" },
    { "label": "Bulgarian", "key": "bg" },
    { "label": "Ukrainian", "key": "uk" },
    { "label": "Croatian", "key": "hr" },
    { "label": "Serbian", "key": "sr" },
    { "label": "Slovenian", "key": "sl" },
    { "label": "Macedonian", "key": "mk" },
    { "label": "Albanian", "key": "sq" },
    { "label": "Estonian", "key": "et" },
    { "label": "Latvian", "key": "lv" },
    { "label": "Lithuanian", "key": "lt" },
    { "label": "Georgian", "key": "ka" },
    { "label": "Armenian", "key": "hy" },
    { "label": "Bengali (Bangladesh)", "key": "bn-BD" },
    { "label": "Bengali (India)", "key": "bn-IN" }
]


export default function Translate() {
    const [textoriginal, setTextoriginal] = useState('');
    const [texttranslation, setTexttranslation] = useState('');
    const [loading, setLoading] = useState(false)
    const [selectedLang, setSelectedLang] = useState({ "label": "English", "key": "en" })
    const [translationLang, setTranslationLang] = useState({ "label": "Spanish", "key": "es" })
    const [isListening, setIsListening] = useState(false)


    useEffect(() => {
        let recognition;

        if (isListening) {
            recognition = new window.webkitSpeechRecognition();
            recognition.continuous = true;
            recognition.interimResults = true;
            recognition.lang = 'en-US';

            recognition.onresult = (event) => {
                let interimTranscript = '';
                let finalTranscript = '';

                for (let i = event.resultIndex; i < event.results.length; i++) {
                    const transcript = event.results[i][0].transcript;
                    if (event.results[i].isFinal) {
                        finalTranscript += transcript + ' ';
                    } else {
                        interimTranscript += transcript;
                    }
                }

                setTextoriginal(finalTranscript);
            };

            recognition.start();
        }

        return () => {
            if (recognition) {
                recognition.stop();
            }
        };
    }, [isListening]);


    const handleListenClick = () => {
        setIsListening(true);
    };

    const handleStopClick = () => {
        setIsListening(false);
    };




    const translatetospan = (text) => {
        translate.engine = "google"; // Or "yandex", "libre", "deepl"
        // translate.key = process.env.GOOGLE_KEY;
        setLoading(true)
        translate(text, { from: selectedLang?.key, to: translationLang?.key }).then((res) => {
            setTexttranslation(res);
            setLoading(false)
        });


    }

    const clipToClipboard = (text) => {
        navigator.clipboard.writeText(text);
        toast.success("Text Copied to Clipboard", {
            position: 'bottom-left'
        })
    };

    return (
        <>
            <Banner title="Translate" background="assets/img/translate-bg.png" height="400px" />
            <Container maxWidth="md">
                <Card sx={{ py: 6, px: 3, my: 8 }}>
                    <Grid container spacing={3}>
                        <Grid item sm={6} xs={12} sx={{ p: 2 }}>
                            <Autocomplete
                                value={selectedLang}
                                onChange={(event, newValue) => {
                                    setSelectedLang(newValue);
                                }}
                                disablePortal
                                id="combo-box-demo"
                                options={langs}
                                sx={{ width: '100%' }}
                                renderInput={(params) => <TextField {...params} fullWidth label="Your Language" />}
                            />
                        </Grid>
                        <Grid item sm={6} xs={12} sx={{ p: 2 }}>
                            <Autocomplete
                                value={translationLang}
                                onChange={(event, newValue) => {
                                    setTranslationLang(newValue);
                                }}
                                disablePortal
                                id="combo-box-demo"
                                options={langs}
                                sx={{ width: "100%" }}
                                renderInput={(params) => <TextField {...params} fullWidth label="Translation Language" />}
                            />
                        </Grid>
                        <Grid style={{ border: '1px solid #ccc', margin: 10, padding: 0, backgroundColor: 'rgba(0, 0, 0, 0.09)' }} item xs={12} sm={5.7}>
                            <Box className="d-flex justify-content-between">
                                <Typography sx={{ px: 2, pt: 2 }} variant="h6">{selectedLang?.label}</Typography>
                                <IconButton
                                    sx={{ height: 'fit-content', m: 1 }}
                                    onClick={() => {
                                        if (isListening) {
                                            handleStopClick()
                                        } else {
                                            handleListenClick()
                                        }
                                    }}
                                >
                                    {
                                        isListening ?
                                            <StopRoundedIcon sx={{ color: 'red}}' }} />
                                            :
                                            <MicRoundedIcon />
                                    }
                                </IconButton>
                            </Box>
                            {
                                isListening ?
                                    <div className='w-100 d-flex justify-content-center align-items-center'>
                                        <img width="50%" src="./assets/img/voice.gif" alt="" />
                                    </div>
                                    :
                                    <div style={{ width: '100%', position: 'relative' }}>
                                        <TextField
                                            value={textoriginal} onChange={(e) => setTextoriginal(e.target.value)}
                                            InputProps={{
                                                sx: { backgroundColor: 'transparent', transition: 'none', padding: 2, }
                                            }} placeholder='Write here...' fullWidth variant='standard' multiline rows={10} />
                                        <IconButton sx={{ position: 'absolute', bottom: 3, right: 3 }} onClick={() => clipToClipboard(textoriginal)}>
                                            <ContentCopyRoundedIcon />
                                        </IconButton>
                                    </div>
                            }

                        </Grid>
                        <Grid item xs={12} sm={5.7} style={{ border: '1px solid #ccc', margin: 10, padding: 0, backgroundColor: 'rgba(0, 0, 0, 0.09)' }}>
                            <Typography sx={{ px: 2, pt: 2 }} variant="h6">{translationLang?.label}</Typography>
                            <div style={{ width: '100%', position: 'relative' }}>
                                <TextField
                                    value={loading ? "..." : texttranslation}
                                    InputProps={{
                                        sx: { backgroundColor: 'transparent', transition: 'none', padding: 2, }
                                    }} variant='standard' fullWidth multiline rows={10} />
                                <IconButton sx={{ position: 'absolute', bottom: 3, right: 3 }} onClick={() => clipToClipboard(texttranslation)}>
                                    <ContentCopyRoundedIcon />
                                </IconButton>
                            </div>
                        </Grid>
                        <Grid xs={12} sx={{ p: 1 }}>
                            <Button disabled={!textoriginal} style={{ width: "100%", background: '#EB1260', color: '#fff' }} variant="contained" className="loginscreen__button loginscreen__buttonone"
                                onClick={() => {
                                    translatetospan(textoriginal)
                                    handleStopClick()
                                }
                                }>
                                Translate
                            </Button>
                        </Grid>
                    </Grid>
                </Card>
            </Container>
        </>
    )
}
