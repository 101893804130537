import React, { useEffect, useRef, useState } from 'react';
import { Box } from '@mui/material'
import axios from 'axios';

const API_KEY = 'swqLSuhqBmdPXRzNhvDQ4dsB'; // Replace with your remove.bg API key

const BackgroundRemoval = ({ img, setIsLoading }) => {
    const canvasRef = useRef(null);
    const [resultUrl, setResultUrl] = useState('');

    useEffect(() => {
        const performBackgroundRemoval = async () => {
            setIsLoading(true)
            const formData = new FormData();
            formData.append('image_file', img); // Replace with the actual path to your input image file

            try {
                const response = await axios.post('https://api.remove.bg/v1.0/removebg', formData, {
                    headers: {
                        'X-Api-Key': API_KEY,
                    },
                    responseType: 'arraybuffer',
                });

                const result = response.data;

                const blob = new Blob([result], { type: 'image/png' });
                const url = URL.createObjectURL(blob);

                setResultUrl(url);
                setIsLoading(false)
            } catch (error) {
                console.error('Background removal error:', error);
            }
        };

        performBackgroundRemoval();
    }, []);

    return (
        <div>
            {resultUrl && (
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', gap: '20px' }}>

                    <a href={resultUrl} download
                        style={{
                            color: '#fff',
                            height: 'fit-content',
                            textShadow: 'none',
                            backgroundColor: '#EB1260',
                            borderRadius: '8px',
                            padding: '10px 30px',
                            marginLeft: '24px'
                        }}
                    >
                        Download Image
                    </a>
                    <div className='background-div'>
                        <img
                            src={resultUrl}
                            alt="Background Removed"
                            ref={canvasRef}
                            style={{ width: '350px' }}
                        />
                    </div>
                </Box>

            )}
        </div>
    );
};

export default BackgroundRemoval;
