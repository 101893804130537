import React, { useState, useRef } from 'react'
import QRCode from 'qrcode.react';
import html2canvas from 'html2canvas';
import Banner from '../common/banner'
import {
    TextField,
    Container,
    Card,
    Button,
} from '@mui/material'
export default function QRGenerator() {
    const [rawText, setRawText] = useState("");
    const qrCodeRef = useRef(null);
    const [generate, setGenerate] = useState(false)

    const handleDownload = () => {
        html2canvas(qrCodeRef.current).then((canvas) => {
            const qrCodeImage = canvas.toDataURL('image/png');
            const a = document.createElement('a');
            a.href = qrCodeImage;
            a.download = 'qrcode.png';
            a.click();
        });
    };

    return (
        <>
            <Banner title="Generate QR Codes" background="assets/img/qr-code.jpg" height="400px" />
            <Container maxWidth="md">
                <Card sx={{ background: '#fff', py: 6, px: 3, my: 8, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <TextField
                        onChange={(e) => setRawText(e.target.value)}
                        placeholder='Write something...'
                        fullWidth
                    />
                    <Button sx={{ m: 2, background: '#EB1260' }} variant='contained' disabled={rawText === ""}
                        onClick={async () => {
                            setGenerate(true)
                        }}
                    >
                        Generate QR Code
                    </Button>

                    {
                        generate &&
                        <Button onClick={handleDownload} sx={{ m: 2, background: '#EB1260' }} variant='contained'>Download QR Code</Button>
                    }
                    {generate && (
                        <div ref={qrCodeRef}>
                            <QRCode value={rawText} />
                        </div>
                    )}
                </Card>
            </Container>

        </>
    )
}
