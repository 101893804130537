import React, { useState, useEffect } from 'react'
import Banner from '../common/banner'
import LazzyLoading from '../common/LazzyLoading'
import {
    Container,
    Card,
    TextField,
    Button,
    IconButton,
    Box
} from '@mui/material'
import ContentCopyRoundedIcon from '@mui/icons-material/ContentCopyRounded';
import toast from 'react-hot-toast'

export default function AudioToText() {
    const [isScaned, setIsScaned] = useState(false)
    const [isRecording, setIsRecording] = useState(false);
    const [transcript, setTranscript] = useState('');
    const [timer, setTimer] = useState(0)

    useEffect(() => {
        let recognition;

        if (isRecording) {
            recognition = new window.webkitSpeechRecognition();
            recognition.continuous = true;
            recognition.interimResults = true;
            recognition.lang = 'en-US';

            recognition.onresult = (event) => {
                let interimTranscript = '';
                let finalTranscript = '';

                for (let i = event.resultIndex; i < event.results.length; i++) {
                    const transcript = event.results[i][0].transcript;
                    if (event.results[i].isFinal) {
                        finalTranscript += transcript + ' ';
                    } else {
                        interimTranscript += transcript;
                    }
                }

                setTranscript(finalTranscript);
            };

            recognition.start();
        }

        return () => {
            if (recognition) {
                recognition.stop();
            }
        };
    }, [isRecording]);

    useEffect(() => {
        let intervalId;

        if (isRecording) {
            intervalId = setInterval(() => {
                setTimer((prevTimer) => prevTimer + 1000); // Increase the timer by 10 milliseconds
            }, 1000);
        }

        return () => {
            clearInterval(intervalId); // Clean up the interval on component unmount
        };
    }, [isRecording]);

    const handleListenClick = () => {
        setIsRecording(true);
    };

    const handleStopClick = () => {
        setIsRecording(false);
        setIsScaned(true)
        setTimer(0)
    };

    const formatTime = (time) => {
        const hours = Math.floor(time / 3600000);
        const minutes = Math.floor((time % 3600000) / 60000);
        const seconds = Math.floor((time % 60000) / 1000);

        const formattedHours = String(hours).padStart(2, '0');
        const formattedMinutes = String(minutes).padStart(2, '0');
        const formattedSeconds = String(seconds).padStart(2, '0');

        return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
    };

    const clipToClipboard = () => {
        navigator.clipboard.writeText(transcript);
        toast.success("Text Copied to Clipboard", {
            position: 'bottom-left'
        })
    };


    return (
        <>
            <Banner title="Convert Your Voice to Text" background="assets/img/audio-to-text.png" height="450px" />
            <Container maxWidth="md" sx={{ my: 8 }}>
                <Card sx={{ py: 6, px: 3, flexDirection: 'column' }} className='d-flex justify-content-center align-items-center'>
                    {
                        isScaned ?
                            <>
                                <div style={{ width: '100%', position: 'relative' }}>
                                    <TextField multiline sx={{ background: '#fff' }} fullWidth minRows={5} value={transcript} />
                                    <IconButton sx={{ position: 'absolute', bottom: 3, right: 3 }} onClick={clipToClipboard}>
                                        <ContentCopyRoundedIcon />
                                    </IconButton>
                                </div>
                                <Button onClick={() => setIsScaned(false)} variant='contained' sx={{ m: 2, height: 'fit-content', background: '#EB1260' }}>Start Again ?</Button>

                            </>
                            :
                            <>
                                <Box sx={{ display: 'flex', flexDirection: { sm: 'row', xs: 'column' }, justifyContent: 'center', alignItems: 'center' }} >
                                    <button className="btn btn-primary" onClick={handleListenClick}>Start Listening</button>
                                    <span className="text-danger" style={{ textShadow: 'none', margin: 20 }}>{formatTime(timer)}</span>
                                    <button className="btn btn-danger" onClick={handleStopClick}>Stop Listening</button>
                                </Box>
                            </>
                    }
                </Card>
            </Container>
        </>
    )
}
