import axios from 'axios'

const API_URL = 'https://zoomaitools.com'
// const API_URL = 'http://localhost:4000'
const GOOGLE_API_KEY = "AIzaSyDvH5Py6Je5lbY2sdQ6BQ1Nye5Sywa-o2E"

export const downloadYoututbeVideo = async (url) => {
    try {
        const response = await axios.get(`${API_URL}/download?url=${url}`);
        let resUrl = response?.data?.url
        let downloadableLink = ""
        resUrl?.split("/")?.map((item, index) => {
            if (index === 0) {
                downloadableLink = item
            } else if (index === 6) {
                downloadableLink = downloadableLink + "/" + 'fl_attachment:' + item
            } else {
                downloadableLink = downloadableLink + "/" + item
            }
        })
        console.log("Downloadable Link: " + downloadableLink)
        const downloadLink = document.createElement('a');
        downloadLink.href = downloadableLink
        downloadLink.download = 'video.mp4';
        // downloadLink.target = '_blank'
        downloadLink.click();
        return response?.data?.url
    } catch (error) {
        console.error('Error:', error.message);
    }

};


export const downloadYoututbeThumbnail = async (url) => {

    const getVideoId = (url) => {
        // Extract video ID from the URL
        const videoId = url.split("?v=")[1];
        return videoId;
    };

    try {
        const response = await axios.get(
            `https://www.googleapis.com/youtube/v3/videos?id=${getVideoId(
                url
            )}&key=${GOOGLE_API_KEY}&part=snippet`
        );
        const data = response.data;

        if (data.items && data.items.length > 0) {
            const thumbnails = data.items[0].snippet.thumbnails;
            const maxResThumbnail = thumbnails.maxres
                ? thumbnails.maxres.url
                : thumbnails.high.url;
            return maxResThumbnail;
        } else {
            return null
        }
    } catch (error) {
        return null
    }
};

export const saveImage = async (url) => {
    // /download-image
    try {
        const response = await fetch(`${API_URL}/download-image?url=${url}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
            responseType: 'blob',
        });
        console.log("Response: ", response)
        const blob = await response.blob();
        console.log("blob: ", blob)
        const downloadUrl = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = downloadUrl;
        a.download = 'image.png';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(downloadUrl);
        console.log("ended")
    } catch (error) {
        return null
    }
}
