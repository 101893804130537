import React, { useState } from 'react'
import Banner from '../common/banner'
import {
  Container,
  Card,
  Button
} from '@mui/material'
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';
import BackgroundRemoval from './BackgroundR'
import LazzyLoading from '../common/LazzyLoading'
export default function BGRemover() {
  const [image, setImage] = useState(null)
  const [isLoading, setIsLoading] = useState(false)

  const [imgProcess, setImgProcess] = useState(false)


  return (
    <>
      <Banner title="Remove Image Background" background="assets/img/bg-remove.png" height="400px" >
      </Banner>

      <Container maxWidth="md" sx={{ my: 8 }}>
        <Card sx={{ background: 'rgba(255,255,255, 0.5)', p: 3, }}>
          {
            !image ?
              <div style={{ width: '100%', display: 'flex', justifyContent: 'center', margin: '20px 0px 100px 0px' }}>
                <button className="btn-bg-remove">
                  Drop an image or upload by clicking here
                  <input type="file" className="hidden-input"
                    onChange={(e) => setImage(e.target.files[0])}
                    accept='image/jpg, image/png, image/jpeg'
                  />
                </button>
              </div>
              :
              <>
                <Button
                  variant='text'
                  sx={{ mb: 2, color: '#EB1260', textTransform: 'capitalize' }}
                  startIcon={<ArrowBackRoundedIcon />}
                  onClick={() => window.location.reload()}
                >
                  Back
                </Button>
                {!imgProcess &&
                  <div style={{ width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                    <Button variant='contained' sx={{ m: 2, background: '#EB1260' }} onClick={() => setImgProcess(true)}>Remove Background</Button>
                    <img width={350} src={URL.createObjectURL(image)} alt="" />
                  </div>
                }
                {imgProcess &&
                  <>
                    <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                      {isLoading &&
                        <LazzyLoading />
                      }
                      <BackgroundRemoval setIsLoading={setIsLoading} img={image} />
                    </div>
                  </>
                }
              </>
          }
        </Card>
      </Container >

    </>
  )
}
