import React from 'react'

export default function Banner(props) {
    const { background, title, qout, children, height } = props
    return (
        <>
            <div className="page-hero page-banner bg-image overlay-dark wow fadeInDown" style={{ backgroundImage: `url(${background})`, height: height }}>
                <div className="hero-section">
                    <div className="container text-center wow zoomIn" style={{ textShadow: '2px 2px 0px #ff0000' }}>
                        <span className="subhead">{qout}</span>
                        <h1 className="display-4 text-gredient ">{title}</h1>
                        {children}
                    </div>
                </div>
            </div>
        </>
    )
}
