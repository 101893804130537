import React, { useState } from "react";
import {
  AppBar,
  Button,
  Toolbar,
  Box,
  Grid,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemText,
  Container,
} from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import MenuIcon from '@mui/icons-material/Menu';
export const paths = [
  {
    title: 'Home',
    route: '/',
  },
  {
    title: 'About Us',
    route: '/about-us',
  },
  {
    title: 'Contact Us',
    route: '/contact-us',
  },
]
const Header = () => {
  const location = useLocation();



  const [open, setOpen] = useState(false);

  const toggleDrawer = (isOpen) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setOpen(isOpen);
  };

  window.addEventListener("scroll", function () {
    const header = document.getElementById("main-header");
    const scrollY = window.scrollY;

    if (scrollY > 200) {
      header.classList.add("scrolled");
    } else {
      header.classList.remove("scrolled");
    }
  });

  return (
    <AppBar position="fixed" id="main-header" className="header-main">
      <Toolbar className="nav-toolbar">
        <Container maxWidth="lg">
          <Grid container className="nav-container">
            <Box
              sx={{
                display: { xs: "flex", md: "none" },
                justifyContent: "flex-start",
              }}
            >
              <IconButton
                aria-controls="menu"
                aria-haspopup="true"
                onClick={toggleDrawer(true)}
                color="inherit"
              >
                <MenuIcon className="menu-icon" />
              </IconButton>

              <Drawer
                anchor="left"
                open={open}
                onClose={toggleDrawer(false)}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  "& .MuiDrawer-paper": {
                    width: 300,
                  },
                }}
              >
                <Box
                  role="presentation"
                  // onKeyDown={toggleDrawer(false)}
                  className="drawer"
                >
                  <List>
                    {paths.map((path, index) => (
                      <ListItem
                        onClick={toggleDrawer(false)}
                        onKeyDown={toggleDrawer(false)}
                        key={index}
                        to={path.route}
                        component={Link}
                        className={
                          location.pathname === path.route ? "active" : "nav_link"
                        }
                      >
                        <ListItemText primary={path.title} />
                      </ListItem>
                    ))}
                  </List>
                </Box>
              </Drawer>
            </Box>
            <Link to="/">
              <img className="logo" src="./assets/img/logo.png" alt="" />
            </Link>
            <Box
              sx={{
                display: { xs: "none", md: "flex" },
                justifyContent: "space-between",
                gap: '40px',
                paddingRight: '20px'
              }}
            >
              {paths.map(({ route, title }) => (
                <Button
                  key={route}
                  to={route}
                  component={Link}
                  className={location.pathname === route ? "active" : "menu-item"}
                >
                  {title}
                </Button>
              ))}
            </Box>
          </Grid>
        </Container>

      </Toolbar>
    </AppBar>
  );
};

export default Header;


// export function Header1() {
//     return (
//         <>
//             <header className="wow fadeInUp header-main">
//                 <nav className="navbar">
//                     <div className="container d-flex justify-content-center">
//                         <Link to="/">
//                             <img width="60px" height="60px" src="./assets/img/logo.png" alt="" />
//                             <p className="navbar-brand ml-2 text-gredient">AI TOOLS</p>
//                         </Link>

//                         {/* <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupport"
//                             aria-controls="navbarSupport" aria-expanded="false" aria-label="Toggle navigation">
//                             <span className="navbar-toggler-icon"></span>
//                         </button> */}

//                         {/* <div className="collapse navbar-collapse" id="navbarSupport">
//                             <ul className="navbar-nav ml-auto">
//                                 <li className="nav-item active">
//                                     <a className="nav-link" href="index.html">Home</a>
//                                 </li>
//                                 <li className="nav-item">
//                                     <a className="nav-link" href="./html/services.html">Our Services</a>
//                                 </li>
//                                 <li className="nav-item">
//                                     <a className="nav-link" href="html/about.html">About Us</a>
//                                 </li>

//                                 <li className="nav-item">
//                                     <a className="nav-link" href="html/contact.html">Contact Us</a>
//                                 </li>

//                             </ul>
//                         </div> */}
//                     </div>
//                 </nav>
//             </header>
//         </>
//     )
// }
