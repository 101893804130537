import React, { useState, useRef } from 'react'
import Banner from '../common/banner'
import LazzyLoading from '../common/LazzyLoading'
import {
    Container,
    Card,
    TextField,
    Button,
    IconButton
} from '@mui/material'
import toast from 'react-hot-toast'
import ReadText from 'text-from-image'
import ContentCopyRoundedIcon from '@mui/icons-material/ContentCopyRounded';


export default function ImgTextScanner() {
    const [text, setText] = useState("")
    const [isScaned, setIsScaned] = useState(false)
    const [loading, setLoading] = useState(false)

    const handleImageUpload = async (event) => {
        setLoading(true)
        const imageFile = event?.target?.files[0];
        try {
            ReadText(URL.createObjectURL(imageFile)).then(text => {
                setText(text)
                setIsScaned(true)
                setLoading(false)
            }).catch(err => {
                alert("Please upload a clear image that has a text")
                // toast.error(err)
                setLoading(false)
            })
        } catch (err) {
            alert("Please upload a clear image that has a text")
        }

    };
    const clipToClipboard = () => {
        navigator.clipboard.writeText(text);
        toast.success("Text Copied to Clipboard",{
            position: 'bottom-left'
        })
    };

    return (
        <>
            <Banner title="Image To Text" background="assets/img/ocr-bg.jpg" height="400px" />
            <Container maxWidth="md" sx={{ my: 8 }}>
                <Card sx={{ py: 6, px: 3, flexDirection: 'column' }} className='d-flex justify-content-center align-items-center'>
                    {
                        loading ?
                            <LazzyLoading />
                            :
                            isScaned ?
                                <>
                                    <div style={{ width: '100%', position: 'relative' }}>
                                        <TextField multiline sx={{ background: '#fff' }} fullWidth minRows={5} value={text} />
                                        <IconButton sx={{ position: 'absolute', bottom: 3, right: 3 }} onClick={clipToClipboard}>
                                            <ContentCopyRoundedIcon />
                                        </IconButton>
                                    </div>
                                    <Button onClick={() => {
                                        window.location.reload()
                                    }
                                    } variant='contained' sx={{ m: 2, height: 'fit-content', background: '#EB1260' }}>Select Image Again ?</Button>
                                </>
                                :
                                <button className="btn-bg-remove">
                                    Drop an image or upload by clicking here
                                    <input type="file" className="hidden-input"
                                        onChange={(e) => {
                                            if (e.target.files.length > 0) {
                                                handleImageUpload(e)
                                            } else {
                                                toast.info("You have already scanned image, please refresh to scan again")
                                            }
                                        }}
                                    />
                                </button>
                    }
                </Card>
            </Container>
        </>
    )
}
