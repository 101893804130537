
import Banner from '../common/banner'
import { Link } from 'react-router-dom'


import {
    Grid,
    Card,
    CardContent,
    CardMedia,
    Typography,

} from '@mui/material';

const companyName = "AI TOOLS"
const tools = [
    {
        title: "Download Youtube Videos",
        description: "Our tool offers a <b> user-friendly </b> interface that makes it incredibly simple to <b>Download YouTube</b> videos. Just paste the <b> Video URL,</b> click the <b>Download</b> button, and voila! Your video will be ready for offline enjoyment.",
        media: "/assets/img/youtube-download.png",
        link: "/youtube-download",
    },
    {
        title: "Remove Image Background",
        description: "The tool intelligently detects the main objects in your image, allowing you to remove the background while keeping the subject intact. Focus on what matters most in your visuals. <b>Remove Background</b> in just one click",
        media: "/assets/img/background-remove.jpg",
        link: "/bg-remove",
    },
    // {
    //     title: "Tags Generator",
    //     description: "Boost your search engine rankings and increase your content's visibility with <b>SEO-friendly tags</b>. Our tool suggests tags that are commonly used and recognized by search engines, helping you attract more organic traffic.",
    //     media: "/assets/img/tag-generate.jpeg",
    //     link: "/generate-keywords",
    // },
    {
        title: "Word Counter",
        description: "Word Counter: Instantly count words, characters, sentences, and more. Accurate, user-friendly, and versatile, it's the essential tool for writers, students, and professionals. Accessible online, anytime, anywhere",
        media: "/assets/img/word-count.png",
        link: "/word-count",
    },
    {
        title: "Image To Text",
        description: "Extract text from images effortlessly. Convert printed or handwritten content into editable text with accuracy. A powerful tool for efficient information retrieval and digital content creation.",
        media: "/assets/img/ocr.png",
        link: "/image-scan",
    },
    {
        title: "Audio to Text",
        description: "An audio to text converter tool allows users to convert spoken words from audio files into written text, enabling easy transcription, analysis, and accessibility of audio content.",
        media: "/assets/img/audio-to-text.png",
        link: "/audio-to-text",
    },
    {
        title: "Translation",
        description: "The translation tool facilitates seamless language conversion, empowering users to effortlessly translate text between different languages. Enhancing communication and breaking language barriers with efficiency and precision.",
        media: "/assets/img/translate-bg.png",
        link: "/translate",
    },
    {
        title: "Youtube Thumbnail Downloader",
        description: "YouTube Thumbnail Downloader is a helpful tool that fetches video thumbnails using YouTube Data API. Simply input the video URL and get the thumbnail image for easy access and sharing.",
        media: "/assets/img/thumbnail.png",
        link: "/thumbnail-download",
    },
    {
        title: "QR Code Generator",
        description: "A QR code generator is a handy tool that creates QR (Quick Response) codes. These codes store information, such as URLs or contact details, for easy scanning and quick access.",
        media: "/assets/img/qr-code.jpg",
        link: "/qr-code",
    },
]

const CardComponent = ({ media, title, description, link }) => {
    return (
        <Link to={link}>
            <Card className="custom-card" sx={{ borderRadius: '15px', height: '100%' }}>
                <CardMedia
                    component="img"
                    height="200"
                    image={media}
                    alt="card image"
                />
                <CardContent>
                    <Typography gutterBottom variant="h5" component="div" className="text-primary">
                        {title}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                        <span dangerouslySetInnerHTML={{ __html: description }} />
                        {/* {description} */}
                    </Typography>
                </CardContent>
            </Card>
        </Link>
    )
}
export default function Home() {
    return (
        <>
            <Banner background="assets/img/bg-img-1.jpg" title={companyName} qout="Let's make your life happier, with" height="600px" />
            <div className="bg-light">
                <div className="page-section pb-0">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-6 py-3 wow fadeInLeft">
                                <h2 className="text-secondary">Welcome to <span className="text-primary">{companyName}</span></h2>

                                <p> The aim and priority of <b className="text-primary">{companyName} </b> is to add value
                                    to our consumers' lives by providing smart solutions to
                                    all their problems
                                    <br />
                                    <br />
                                    Do you need to download a YouTube video? Scan Text from Images ? Generate Text by just speeking ? Remove the background
                                    from an image? If so, you've come to the right place! Our website provides a variety of tools to help you with all of these tasks and more.
                                    <br />
                                    <br />
                                    <b className="text-primary">Download YouTube videos:</b> Our YouTube downloader is the easiest way to download videos from YouTube. Simply enter the URL of the video you
                                    want to download and click on the "Download" button. The video will be downloaded in a matter of seconds.
                                    <br />
                                    <br />
                                    {/* <b className="text-primary">Generate tags:</b> Our tag generator can help you create the perfect set of tags for your blog post. Simply enter the title of your
                                    post and our generator will suggest a list of relevant tags.
                                    <br />
                                    <br /> */}
                                    {/* <b className="text-primary">Cancel audio noise:</b> Our audio noise cancellation tool can help you remove unwanted noise from your recordings. Simply
                                    upload your recording and our tool will automatically remove the noise.
                                    <br />
                                    <br /> */}
                                    <b className="text-primary"> Remove image background:</b> Our image background remover can help you remove the background from an image. Simply upload your
                                    image and our tool will automatically remove the background.

                                    <br />
                                    <br />
                                    <b className="text-primary"> Image Text Scanner: </b> Extract text from images effortlessly. Convert printed or handwritten content into editable text with accuracy. A powerful tool for efficient information retrieval and digital content creation.

                                    <br />
                                    <br />
                                    <b className="text-primary"> Translate: </b> The translation tool facilitates seamless language conversion, empowering users to effortlessly translate text between different languages. Enhancing communication and breaking language barriers with efficiency and precision.s

                                </p>



                                {/* <a href="html/about.html" className="btn btn-primary">Learn More</a> */}
                            </div>
                            <div className="col-lg-6 wow fadeInRight" data-wow-delay="400ms">
                                <div className="img-place custom-img-1">
                                    <img src="assets/img/ai-side.png" alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
            <div className="container my-4">
                <h1 className="text-center mb-5 wow zoomIn">Our Services</h1>
                <Grid container justifyContent="center" spacing={3}>
                    {
                        tools.map((item, index) => {
                            return (
                                <Grid item md={4} sm={6} xs={12} key={item.title}
                                    className={index == 0 ? "wow fadeInLeft" : index === 1 ? "wow fadeInUp" : index == 2 ? "wow fadeInRight" : index === 3 ? "wow fadeInRight" : "wow fadeInLeft"}
                                >
                                    <CardComponent media={item.media} title={item.title} description={item.description} link={item.link} />
                                </Grid>
                            )
                        })
                    }
                </Grid>
            </div >
        </>
    )
}
