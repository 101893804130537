import React, { useState } from 'react'
import Banner from '../common/banner'
import {
    TextField,
    Container,
    Card,
} from '@mui/material'
export default function WordCounter() {
    const [value, setValue] = useState("")
    return (
        <>
            <Banner title="Count words of any paragraph" background="assets/img/word-count-bg.png" height="400px" />
            <Container maxWidth="md" sx={{py: 8}}>
                <Card sx={{ background: '#fff', py: 6, px: 3 }}>
                    <TextField
                        onChange={(e) => setValue(e.target.value)}
                        value={value}
                        multiline
                        rows="6"
                        InputProps={{
                            sx: { px: 2, borderRadius: '8px' },
                            // startAdornment: <InputAdornment position="start"><TextSnippetOutlinedIcon color="primary" /></InputAdornment>,
                        }}
                        placeholder='Enter Text Here...'
                        fullWidth />
                    {
                        value !== "" &&
                        <div className="word-count text-center">
                            <p>The text you have entered contains </p>
                            <h3>
                                {value.split(' ').length} words
                            </h3 >
                            <p>and <b>{value.length}</b> characters </p>
                        </div>
                    }
                </Card>
            </Container>
        </>
    )
}
