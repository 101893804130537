import React, { useState } from 'react'
import Banner from '../common/banner'
import {
    TextField,
    Container,
    Card,
    InputAdornment,
    Button,
} from '@mui/material'
import YouTubeIcon from '@mui/icons-material/YouTube';
import InsertLinkIcon from '@mui/icons-material/InsertLink';
import { downloadYoututbeVideo } from '../../_api/index'
import LazzyLoading from '../common/LazzyLoading'
export default function YoutubeDownloader() {

    const [ytLink, setYTLink] = useState("")
    const [isLoading, setIsLoading] = useState(false)

    return (
        <>
            <Banner title="Download Youtube Videos" background="assets/img/youtube-banner.jpg" height="400px" />
            <Container maxWidth="md" sx={{ mt: 5 }}>
                <Card sx={{ background: '#fff', p: 3 }} className='d-flex flex-column align-items-center' >
                    <TextField
                        onChange={(e) => setYTLink(e.target.value)}
                        InputProps={{
                            sx: { px: 2 },
                            startAdornment: <InputAdornment position="start"><InsertLinkIcon color="primary" /></InputAdornment>,
                            endAdornment: <InputAdornment position="end"><YouTubeIcon sx={{ color: 'red' }} /></InputAdornment>

                        }}
                        placeholder='Insert Youtube Link'
                        fullWidth />
                    {
                        isLoading ?
                            <LazzyLoading title="Please wait, Video will start downloading automatically..." />
                            :
                            <Button sx={{ m: 2, background: '#EB1260' }} variant='contained' disabled={ytLink === ""}
                                onClick={async () => {
                                    setIsLoading(true)
                                    await downloadYoututbeVideo(ytLink)
                                    setIsLoading(false)
                                }}
                            >Download Video</Button>
                    }
                </Card>
            </Container>
        </>
    )
}
