import React, { useState, useRef } from 'react'
import Banner from '../common/banner'
import {
    TextField,
    Container,
    Card,
    InputAdornment,
    Button,
    Box,
    Typography
} from '@mui/material'
import YouTubeIcon from '@mui/icons-material/YouTube';
import InsertLinkIcon from '@mui/icons-material/InsertLink';
import { downloadYoututbeThumbnail } from '../../_api/index'
import LazzyLoading from '../common/LazzyLoading'
import html2canvas from 'html2canvas';
import { saveAs } from 'file-saver';
import { saveImage } from '../../_api/index'
export default function ThumbnailDownloader() {
    const [isLoading, setIsLoading] = useState(false)
    const [videoUrl, setVideoUrl] = useState("");
    const [thumbnailUrl, setThumbnailUrl] = useState("");
    const captureRef = useRef(null)
    const handleDownload = async () => {
        let res = await saveImage(thumbnailUrl)
        console.log("Res: ", res)
        // saveAs(thumbnailUrl, "Image.png")
        // if (!captureRef.current) {
        //     console.error('Element not found.');
        //     return;
        // }

        // html2canvas(captureRef.current, { useCORS: true }).then(function (canvas) {
        //     const screenshotData = canvas.toDataURL('image/png', 1.0);
        //     const a = document.createElement('a');
        //     a.href = screenshotData;
        //     a.download = 'screenshot.png'; // You can change the filename here
        //     a.click();
        // });
    }
    return (
        <>
            <Banner title="Download Youtube Video Thumbnails" background="assets/img/youtube-banner.jpg" height="400px" />
            <Container maxWidth="md">
                <Card sx={{ background: '#fff', py: 6, px: 3, my: 8, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <TextField
                        onChange={(e) => setVideoUrl(e.target.value)}
                        InputProps={{
                            sx: { px: 2 },
                            startAdornment: <InputAdornment position="start"><InsertLinkIcon color="primary" /></InputAdornment>,
                            endAdornment: <InputAdornment position="end"><YouTubeIcon sx={{ color: 'red' }} /></InputAdornment>

                        }}
                        placeholder='Insert Youtube Link'
                        fullWidth />
                    {
                        isLoading ?
                            <LazzyLoading title="Loading video thumnail please wait..." />
                            :
                            <>
                                <Button sx={{ m: 2, background: '#EB1260' }} variant='contained' disabled={videoUrl === ""}
                                    onClick={async () => {
                                        setIsLoading(true)
                                        let res = await downloadYoututbeThumbnail(videoUrl)
                                        if (res) {
                                            setThumbnailUrl(res)
                                        } else {
                                            alert("Thumbnail not generated please try again later!")
                                        }
                                        setIsLoading(false)
                                    }}
                                >Fetch Thumbnail</Button>


                                {thumbnailUrl &&
                                    <>
                                        {/* <a href={thumbnailUrl} download > */}
                                        <Button onClick={() => handleDownload()} sx={{ m: 2, background: '#EB1260' }} variant='contained'>Download Thumbnail</Button>
                                        {/* </a> */}
                                        {/* <Typography
                                            sx={{ textShadow: 'none', my: 2 }}>
                                            If image is not downloaded by clicking "Download Thumbnail" button, your browswer does not support
                                            direct download. Please right click on the image and try Save Image As... option.
                                        </Typography> */}
                                    </>}

                            </>

                    }
                    {thumbnailUrl && (
                        <div ref={captureRef}>
                            <img src={thumbnailUrl} alt="Video Thumbnail" style={{ width: "100%" }} />
                        </div>
                    )}
                </Card>
            </Container>

        </>
    )
}
