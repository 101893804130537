import { Routes, Route } from 'react-router-dom'
import Home from './components/home';
import Header from './components/common/header'
import YoutubeDownloader from './components/youtube-downloader';
import BGRemover from './components/bg-remover';
import WordCounter from './components/word-counter';
import ImgTextScanner from './components/img-text-scanner';
import AudioToText from './components/audio-to-text';
import Translate from './components/translator/index'
import ThumbnailDownloader from './components/thumbnail-downloader'
import { Toaster } from 'react-hot-toast'
import QRGenerator from './components/qr-generator';




function App() {
  return (
    <>
      <Toaster
        position="top-center"
        reverseOrder={false}
      />
      <div className="back-to-top"></div>
      <Header />
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path="/youtube-download" element={<YoutubeDownloader />} />
        <Route exact path="/bg-remove" element={<BGRemover />} />
        <Route exact path="/word-count" element={<WordCounter />} />
        <Route exact path="/image-scan" element={<ImgTextScanner />} />
        <Route exact path="/audio-to-text" element={<AudioToText />} />
        <Route exact path="/translate" element={<Translate />} />
        <Route exact path="/thumbnail-download" element={<ThumbnailDownloader />} />
        <Route exact path="/qr-code" element={<QRGenerator />} />
      </Routes>

    </>
  );
}

export default App;
