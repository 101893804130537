const LazzyLoading = ({title}) => {
    return <div>
        <div className="lazzy-container d-flex flex-column">
            {title && <h6 style={{color: '#EB1260', textShadow: 'none'}}>{title}</h6>}
            <div className="lds-roller">
                <div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div>
            </div>
        </div>
    </div>
}
export default LazzyLoading